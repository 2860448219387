* {
  box-sizing: border-box;
}

@-moz-document url-prefix() {
  br {
    margin-top: 14px;
  }
}

a {
  text-decoration: none;
}

button,
button.MuiButton-root,
span.MuiTypography-body1 {
  font: 400 17px Lato;
  font-family: Lato, sans-serif;
}

.MuiRadio-root {
  color: #bfbfbf;

  &.radio-button.Mui-checked {
    color: #37c2cc;
  }

  .MuiSvgIcon-root {
    font-size: 1.1em;
  }
  
}

@media (min-width: 1280px) {
  .navbar-wrapper div.MuiContainer-maxWidthLg {
    max-width: 100%;
  }
}
